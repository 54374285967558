import React from 'react'
import styled from "styled-components"
import media from "styled-media-query";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/layout";
import Header from "../../components/Header";
import {Section} from "../../components/Theme";
import Footer from '../../components/footer';
import Carousel from "nuka-carousel";

const SlideButton = styled.button`
  font-size: 24px;
  padding: 10px 10px;
`;

const PageHeader = styled.h1`
     grid-column: 2/8;
    padding: 40px 0;
    font-weight: 100;
    font-size: 52px;
    line-height: 79px;
    ${media.lessThan("large")`
        padding-bottom: 20px;
        font-size: 36px;
        line-height: 48px;
    `}
`

const CarouselFrame = styled(Carousel)`
    width: 100%;
    li {
        cursor: default;

    }
    .slider-frame {
        width: 100%;
    }
`;



const FeaturedImage = styled.div`
    grid-column: 2/12;

`
const PageContent = styled.div `
    grid-column: 3/11;
    padding: 80px 0;
    ${media.lessThan("large")`
    padding-top: 20px;
    `}

`
const LinkGrid = styled.div`
     display: grid;
    max-width: 1640px;
        width: 100%;
    grid-row-gap: 0px;
    grid-row: 4/5;
    grid-column: 3/11;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 80px;
    width: 100%;
    grid-column-gap: 50px;
    margin-bottom: 80px;
    ${media.lessThan("large")`
    display: block !important;
    `}
`

const BookLink = styled.a`
    font-size: 18px;
    line-height: 22px;
    background: #000;
    color: #fff;
    display: block;
    text-align: center;
    padding: 20px 30px;
    text-decoration: none;
    margin-bottom: 10px;
`


const OverviewCalendar = ({ data: { cal } }) => (

    <Layout parent="book-page">
      <Header  content_color="black"/>
      <Section>

            <PageHeader>{cal.title}</PageHeader>
          
            <FeaturedImage>
                <CarouselFrame
                    width="100%"
                    transitionMode="fade"
                    autoplayIntervasl={3000}
                    wrapAround
                    slideWidth={1.0}
                    initialSlidewidth={1.0}
                    autoplay

                    renderCenterLeftControls={({ previousSlide }) => (
                        <SlideButton onClick={previousSlide}>&larr;</SlideButton>
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                        <SlideButton onClick={nextSlide}>&rarr;</SlideButton>
                    )}

                >
                    <Img fluid={cal.featuredImage.fluid} alt={cal.title} />
                    {cal.calendarImages.map((img, index) => (
                        <Img fluid={img.fluid} alt="" key={`img-${index}`} />
                    ))}
                </CarouselFrame>
            </FeaturedImage>
            <PageContent>
                <div className="body-copy" dangerouslySetInnerHTML={{ __html: cal.description }}></div>
         
            </PageContent>
            <LinkGrid>
                {cal.links.map(link => (
                    <div className={`book-${link.model.apiKey}`} key={link.id}>
                        {link.model.apiKey === "link" && (
                            <BookLink href={link.url} target="_blank">{link.name}</BookLink>
                        )}
                    </div>
                ))}
            </LinkGrid>
      </Section>
      <Footer />
  </Layout>
)

export default OverviewCalendar

export const query = graphql`
  query OverviewCalendarQuery {
    cal: datoCmsCalendar {
        links {
          name
          url
          model {
            apiKey
          }
        }
        title
        description
        calendarImages {
            fluid(
                maxWidth: 1600
                imgixParams: {
                    dpr: 1
                    w: "1600"
                    fit: "clip"
                    auto: "compress"
                }
            ) {
                ...GatsbyDatoCmsFluid
            }
        }
        featuredImage {
            fluid(
                maxWidth: 1600
                imgixParams: {
                    dpr: 1
                    w: "1600"
                    fit: "clip"
                    auto: "compress"
                }
            ) {
                ...GatsbyDatoCmsFluid
            }
        }
    }
  }
`
